import React, { memo, useCallback, useState } from 'react';

import styles from './styles.module.css';

interface AllProps {
    onChange: (value: boolean) => void;
    isOpened: boolean;
}

const BurgerComponent = (props: AllProps) => {
    const { onChange, isOpened } = props;

    const handleOpened = useCallback(() => onChange(!isOpened), [isOpened]);

    const getClassName = useCallback(
        () => (isOpened ? styles.burger_isOpened : styles.burger),
        [isOpened],
    );

    return (
        <div className={getClassName()} onClick={handleOpened}>
            <div className={styles.burger__item} />
            <div className={styles.burger__item} />
            <div className={styles.burger__item} />
        </div>
    );
};

export const Burger = memo(BurgerComponent);
