import { Reducer } from 'redux';

import {
    getDefaultState,
    getIsErrorState,
    getIsFetchedState,
    getIsLoadingState,
} from '../utils/networkGenarators';

import { CategoriesActionTypes, categoriesState } from './types';

export const initialState: categoriesState = {
    network: getDefaultState(),
    hasMore: true,
    data: [],
};

const reducer: Reducer<categoriesState> = (state = initialState, action) => {
    switch (action.type) {
        case CategoriesActionTypes.CATEGORIES_REQUEST: {
            return {
                ...state,
                network: getIsLoadingState(),
            };
        }
        case CategoriesActionTypes.CATEGORIES_SUCCESS: {
            const newData = [...state.data, ...action.payload];
            const hasMore = Boolean(action.payload.length);
            return {
                ...state,
                data: newData,
                network: getIsFetchedState(),
                hasMore,
            };
        }
        case CategoriesActionTypes.CATEGORIES_ERROR: {
            return {
                ...state,
                network: getIsErrorState(action.payload),
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as categoriesReducer };
