import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper } from '../content-wrapper';

import emailIcon from '../../assets/icon-email.svg';
import locationIcon from '../../assets/location-icon.svg';
import phoneIcon from '../../assets/icon-phone.svg';
import fbIcon from '../../assets/icon-fb.svg';
import instaIcon from '../../assets/icon-insta.svg';
import iconPhone from '../../assets/icon-phone.svg';
import logo from '../../assets/logo.svg';
import { TextWithIcon } from '../text-with-icon';

const FooterComponent = () => {
    return (
        <div className={styles.footer}>
            <ContentWrapper>
                <div className={styles.footer__content}>
                    <div className={styles.footer__item_logo}>
                        <img
                            src={logo}
                            alt=""
                            className={styles.footer__logo}
                        />
                        <TextWithIcon
                            isDiffOnMobile={true}
                            text={
                                <>
                                    Санкт-Петербург, <br />
                                    Комендантский пр д.4 лит А оф.503
                                </>
                            }
                            iconSrc={locationIcon}
                        />
                    </div>
                    <div className={styles.footer__item_phones}>
                        <div className={styles.footer__phone}>
                            <a href="tel:+7 (812) 449-30-01">
                                <TextWithIcon
                                    text="+7 (812) 449-30-01"
                                    iconSrc={phoneIcon}
                                />
                            </a>
                        </div>
                        <div className={styles.footer__phone}>
                            <a href="tel:+7 (812) 456-70-73">
                                <TextWithIcon
                                    iconSrc={phoneIcon}
                                    text="+7 (812) 456-70-73"
                                />
                            </a>
                        </div>
                        <div className={styles.footer__phone}>
                            <a href="mailto:1zublab@mail.ru">
                                <TextWithIcon
                                    text="1zublab@mail.ru"
                                    iconSrc={emailIcon}
                                />
                            </a>
                        </div>
                    </div>
                    <div className={styles.footer__item_socials}>
                        <a
                            className={styles.footer__icon}
                            href="https://mobile.facebook.com/1zublab/"
                            target="_blank"
                        >
                            <img
                                src={fbIcon}
                                alt=""
                                className={styles.footer__icon}
                            />
                        </a>
                        <a
                            className={styles.footer__icon}
                            href="https://instagram.com/1zublab"
                            target="_blank"
                        >
                            <img
                                src={instaIcon}
                                alt=""
                                className={styles.footer__icon_insta}
                            />
                        </a>
                        <a href="https://wa.me/89995274028">
                            <img
                                className={styles.footer__icon}
                                src={iconPhone}
                                alt=""
                            />
                        </a>
                    </div>
                </div>
                <div className={styles.footer__bottom}>
                    <div className={styles.footer__bottomText}>
                        Все права защищены, 2021г.
                    </div>
                    <a href="https://yanalapshova.ru" target="_blank">
                        <div className={styles.footer__bottomText_second}>
                            Дизайн сайта: yanalapshova.ru
                        </div>
                    </a>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const Footer = memo(FooterComponent);
