import { Network } from '../types';

export enum AdminInfoActionTypes {
    ADMIN_INFO_REQUEST = '@@adminInfo/ADMIN_INFO_REQUEST',
    ADMIN_INFO_SUCCESS = '@@adminInfo/ADMIN_INFO_SUCCESS',
    ADMIN_INFO_ERROR = '@@adminInfo/ADMIN_INFO_ERROR',

    ADMIN_INFO_CHECK_SID_REQUEST = '@@adminInfo/ADMIN_INFO_CHECK_SID_REQUEST',
}

export type adminInfoState = {
    readonly network: Network;
} & AdminState;

export type AdminState = AdminInfoResponseData;

export type AdminInfoResponseData = { type: 'admin'; name: string };

export type AdminInfoRequest = {
    name: string;
    password: string;
};
