import React, { memo } from 'react';

import styles from './styles.module.css';

import logo from '../../assets/logo.svg';
import { ContentWrapper } from '../content-wrapper';
import { HeaderMenu } from './header-menu';

const HeaderComponent = () => {
    return (
        <div className={styles.header}>
            <ContentWrapper>
                <div className={styles.header__content}>
                    <img
                        src={logo}
                        className={styles.header__logo}
                        alt="Логотип"
                    />
                    <div className={styles.header__menu}>
                        <HeaderMenu />
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const Header = memo(HeaderComponent);
