import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper, SIZES } from '../content-wrapper';

const VideoComponent = () => {
    return (
        <ContentWrapper size={SIZES.sm}>
            <div className={styles.video}>
                <div className={styles.video__content}>
                    <iframe
                        className={styles.video__iframe}
                        src="https://www.youtube.com/embed/5ybYbXoJh88"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </div>
        </ContentWrapper>
    );
};

export const Video = memo(VideoComponent);
