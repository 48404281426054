import React, { memo } from 'react';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { categoriesState } from '../../store/categories/types';
import { getCategories } from '../../store/categories/actions';
import { Gallery } from '../../components/gallery';

type PropsFromState = categoriesState;

interface PropsFromDispatchToProps {
    getCategories: typeof getCategories;
}

export type AllProps = PropsFromState & PropsFromDispatchToProps;

const CategoriesContainerComponent = (props: AllProps) => {
    return <Gallery {...props} />;
};

const mapStateToProps = ({ categories }: ApplicationState): PropsFromState => ({
    ...categories,
});

const mapDispatchToProps = {
    getCategories,
};

const categoriesContainerMemoize = memo(CategoriesContainerComponent);

export const CategoriesContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(categoriesContainerMemoize);
