import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { CombinedState, combineReducers, Reducer } from 'redux';
import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';

import { adminInfoReducer } from './admin/reducer';
import adminInfoSaga from './admin/sagas';
import { adminInfoState } from './admin/types';
import { newsSaga } from './news/sagas';
import { newsState } from './news/types';
import { newsReducer } from './news/reducer';
import { categoriesSaga } from './categories/sagas';
import { categoriesReducer } from './categories/reducer';
import { categoriesState } from './categories/types';
import { photosSaga } from './photos/sagas';
import { photosReducer } from './photos/reducer';
import { photosState } from './photos/types';

export interface ApplicationState {
    router: RouterState<{ pathname: string }>;
    adminInfo: adminInfoState;
    news: newsState;
    categories: categoriesState;
    photos: photosState;
}

export const createRootReducer = (
    history: History,
): Reducer<CombinedState<ApplicationState>> =>
    combineReducers({
        router: connectRouter(history),
        adminInfo: adminInfoReducer,
        news: newsReducer,
        categories: categoriesReducer,
        photos: photosReducer,
    });

export function* rootSaga(): Generator<AllEffect<ForkEffect>> {
    yield all([
        fork(adminInfoSaga),
        fork(newsSaga),
        fork(categoriesSaga),
        fork(photosSaga),
    ]);
}
