import React, { memo, useCallback } from 'react';
//import PDFViewer from 'mgr-pdf-viewer-react';

import { ContentWrapper } from '../content-wrapper';
import { Button } from '../button';

import styles from './styles.module.css';


const PriceComponent = () => {
    const handleDownload = useCallback(() => {
        return;
    }, []);

    return (
        <div className={styles.price}>
            <ContentWrapper>
                <div className={styles.price__topBlock}>
                    <div className={styles.price__title}>Скачать</div>
                    <div className={styles.price__buttons}>
                        <div className={styles.price__button}>
                            <a href="/uploads/Заказ%20наряд.pdf" download>
                                <Button onClick={handleDownload}>
                                    <div className={styles.price__buttonText}>
                                        Заказ Наряд
                                    </div>
                                </Button>
                            </a>
                        </div>

                        <div className={styles.price__button}>
                            <a href="/uploads/Прайс%20лист.pdf" download>
                                <Button style="purple" onClick={handleDownload}>
                                    <div className={styles.price__buttonText}>
                                        Прайс
                                    </div>
                                </Button>
                            </a>
                        </div>
                    </div>
					{/*
                    <div className={styles.price__pdf}>
                        <PDFViewer
                            document={{
                                url: '/uploads/Прайс%20лист.pdf',
                            }}
                        />
                    </div>
					*/}
                </div>
            </ContentWrapper>
        </div>
    );
};

export const Price = memo(PriceComponent);
