import React, { memo } from 'react';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { photosState } from '../../store/photos/types';
import { getPhotos, getPhotosClear } from '../../store/photos/actions';
import { GallerySingle } from '../../components/gallery-single';

type PropsFromState = photosState;

interface PropsFromDispatchToProps {
    getPhotos: typeof getPhotos;
    getPhotosClear: typeof getPhotosClear;
}

export type AllProps = PropsFromState & PropsFromDispatchToProps;

const PhotosContainerComponent = (props: AllProps) => {
    return <GallerySingle {...props} />;
};

const mapStateToProps = ({ photos }: ApplicationState): PropsFromState => ({
    ...photos,
});

const mapDispatchToProps = {
    getPhotos,
    getPhotosClear,
};

const photosContainerMemoize = memo(PhotosContainerComponent);

export const PhotosContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(photosContainerMemoize);
