import React, { memo } from 'react';
import { PhotosContainer } from '../../containers/photos';

const GallerySinglePageComponent = () => {
    return (
        <>
            <PhotosContainer />
        </>
    );
};

export const GallerySinglePage = memo(GallerySinglePageComponent);
