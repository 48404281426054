import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper, SIZES } from '../content-wrapper';

const AboutComponent = () => {
    return (
        <div className={styles.about}>
            <ContentWrapper size={SIZES.xs}>
                <div className={styles.about__title}>О компании</div>
                <div className={styles.about__description}>
                    «Первая зуботехническая лаборатория» - зуботехническая
                    лаборатория в Санкт-Петербурге, которая работает на
                    стоматологическом рынке с 2012 года. Лаборатория «ПЗЛ»
                    выполняет большой спектр зуботехнических работ.
                    <br />
                    <br />В штате компании работают более 50 квалифицированных зубных
                    техников. В основе успешной работы предприятия лежит
                    использование лучших материалов и оборудования, цифровые и
                    аналоговые технологии последнего поколения. Для производства
                    каркасов из оксида циркония применяется оборудование
                    мирового лидера фирмы 3shape.
                </div>
            </ContentWrapper>
        </div>
    );
};

export const About = memo(AboutComponent);
