import { action } from 'typesafe-actions';

import { RequestError } from '../types';
import {
    AdminInfoActionTypes,
    AdminInfoRequest,
    AdminInfoResponseData,
} from './types';

export const getAdminInfo = (
    data: AdminInfoRequest,
): { type: AdminInfoActionTypes; payload: AdminInfoRequest } =>
    action(AdminInfoActionTypes.ADMIN_INFO_REQUEST, data);

export const checkSid = (): { type: AdminInfoActionTypes } =>
    action(AdminInfoActionTypes.ADMIN_INFO_CHECK_SID_REQUEST);

export const getAdminInfoSuccess = (
    data: AdminInfoResponseData,
): { type: AdminInfoActionTypes; payload: AdminInfoResponseData } =>
    action(AdminInfoActionTypes.ADMIN_INFO_SUCCESS, data);

export const getAdminInfoError = (
    error: RequestError,
): { type: AdminInfoActionTypes; payload: RequestError } =>
    action(AdminInfoActionTypes.ADMIN_INFO_ERROR, error);
