import React, { useCallback } from 'react';

import styles from './styles.module.css';
import { history } from '../../../index';
import { routerConstants } from '../../../router-constants';

export const Breadcrumbs = ({ title }: { title: string }) => {
    if (!title) return null;

    const handleRedirect = useCallback(() => {
        history.push(routerConstants.gallery);
    }, []);

    return (
        <div className={styles.breadcrumbs}>
            <a className={styles.breadcrumbs__link} onClick={handleRedirect}>
                Галерея /
            </a>
            <a className={styles.breadcrumbs__category}> {title}</a>
        </div>
    );
};
