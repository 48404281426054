import React, { memo, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { GalleryItem } from './gallery-item';
import { ContentWrapper } from '../content-wrapper';
import { Loader } from '../loader';

import styles from './styles.module.css';
import { AllProps } from '../../containers/categories';

const GalleryComponent = (props: AllProps) => {
    const renderedItems = useMemo(() => {
        return props.data.map((item) => (
            <div key={item._id} className={styles.gallery__item}>
                <GalleryItem {...item} />
            </div>
        ));
    }, [props.data]);

    const handleLoadMore = useCallback(() => {
        if (props.network.isLoading) return;
        if (props.network.errors) return;

        const skip = props.data.length;

        props.getCategories({ skip, limit: 10 });
    }, [props.data, props.network, props.network]);

    return (
        <div className={styles.gallery}>
            <ContentWrapper>
                <InfiniteScroll
                    loader={<Loader />}
                    hasMore={props.hasMore}
                    loadMore={handleLoadMore}
                    pageStart={0}
                >
                    <div className={styles.gallery__items}>{renderedItems}</div>
                </InfiniteScroll>
            </ContentWrapper>
        </div>
    );
};

export const Gallery = memo(GalleryComponent);
