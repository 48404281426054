import React, { memo } from 'react';

import styles from './styles.module.css';

interface AllProps {
    imageLink: string;
    description: string;
    forSlider?: boolean;
}

const GallerySingleItemComponent = (props: AllProps) => {
    if (props.forSlider) {
        return (
            <div className={styles.gallerySingleItem_forSlider}>
                <img
                    className={styles.gallerySingleItem__sliderImage}
                    src={props.imageLink}
                    alt={props.description}
                />
                <div className={styles.gallerySingleItem__textContent}>
                    <div
                        className={
                            styles.gallerySingleItem__description_forSlider
                        }
                    >
                        {props.description}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.gallerySingleItem}>
                <div
                    className={styles.gallerySingleItem__image}
                    style={{ backgroundImage: `url('${props.imageLink}')` }}
                />
                <div className={styles.gallerySingleItem__description}>
                    {props.description}
                </div>
            </div>
        );
    }
};

export const GallerySingleItem = memo(GallerySingleItemComponent);
