import React, { memo } from 'react';
import { Price } from '../../components/price';

const PricePageComponent = () => {
    return (
        <div>
            <Price />
        </div>
    );
};

export const PricePage = memo(PricePageComponent);
