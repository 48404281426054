import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import configureStore from './configureStore';
import Main from './main';
import reportWebVitals from './reportWebVitals';
import { ApplicationState } from './store';
import './index.css';

export const history = createBrowserHistory();

const initialState = (window as Window &
    typeof globalThis & { INITIAL_REDUX_STATE: ApplicationState })
    .INITIAL_REDUX_STATE;
const store = configureStore(history, initialState);

ReactDOM.render(
    <Main store={store} history={history} />,
    document.getElementById('root'),
);

reportWebVitals();
