import React, { memo, ReactNode } from 'react';

import styles from './styles.module.css';

interface AllProps {
    text: ReactNode;
    isDiffOnMobile?: boolean;
    iconSrc?: string;
    invert?: boolean;
}

const TextWithIconComponent = (props: AllProps) => {
    return (
        <div
            className={
                props.isDiffOnMobile
                    ? styles.iconText_diffOnMobile
                    : styles.iconText
            }
        >
            <img
                src={props.iconSrc}
                alt=""
                className={
                    props.iconSrc
                        ? styles.iconText__icon
                        : styles.iconText__icon_none
                }
            />
            <div
                className={
                    props.invert
                        ? styles.iconText__text_dark
                        : styles.iconText__text
                }
            >
                {props.text}
            </div>
        </div>
    );
};

export const TextWithIcon = memo(TextWithIconComponent);
