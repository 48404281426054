import React, { memo, useMemo } from 'react';

import styles from './styles.module.css';

import locationIcon from '../../assets/location-icon.svg';
import { ContentWrapper, SIZES } from '../content-wrapper';

const MapComponent = () => {
    return (
        <div className={styles.map}>
            <ContentWrapper size={SIZES.md}>
                <div className={styles.map__content}>
                    <div className={styles.map__iconText}>
                        <img
                            className={styles.map__icon}
                            src={locationIcon}
                            alt=""
                        />
                        <div className={styles.map__text}>
                            Санкт-Петербург, <br />
                            Комендантский пр д.4 лит А оф.503
                        </div>
                    </div>
                    <div className={styles.map__iframe}>
                        <div
                            style={{ position: 'relative', overflow: 'hidden' }}
                        >
                            <a
                                href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
                                style={{
                                    color: '#eee',
                                    fontSize: '12px',
                                    position: 'absolute',
                                    top: '0',
                                }}
                            >
                                Санкт‑Петербург
                            </a>
                            <a
                                href="https://yandex.ru/maps/2/saint-petersburg/house/komendantskiy_prospekt_4/Z0kYdAFkQUYCQFhqfXxxc35rZw==/?ll=30.265289%2C60.002573&utm_medium=mapframe&utm_source=maps&z=16.36"
                                style={{
                                    color: '#eee',
                                    fontSize: '12px',
                                    position: 'absolute',
                                    top: '14px',
                                }}
                            >
                                Комендантский проспект, 4 — Яндекс.Карты
                            </a>
                            <iframe
                                src="https://yandex.ru/map-widget/v1/-/CCU4v-RKgD"
                                width="100%"
                                height={600}
                                frameBorder="0"
                                allowFullScreen={true}
                                style={{ position: 'relative' }}
                            />
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const Map = memo(MapComponent);
