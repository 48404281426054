import React, { memo, useCallback } from 'react';
import { history } from '../../../../index';
import styles from './styles.module.css';
import { routerConstants } from '../../../../router-constants';
import { AllProps } from '../../../../containers/header-menu-items';

const moc = [
    {
        text: 'Главная',
        link: routerConstants.root,
    },
    {
        text: 'Прайс лист',
        link: routerConstants.price,
    },
    {
        text: 'Галерея',
        link: routerConstants.gallery,
    },
    {
        text: 'Контакты',
        link: routerConstants.contacts,
    },
];

const ItemsComponent = (props: AllProps) => (
    <>
        {moc.map((item) => {
            const handleRedirect = useCallback(() => {
                props.onClick();
                history.push(item.link);
            }, []);

            const getClassName = useCallback(() => {
                return item.link === history.location.pathname
                    ? styles.headerMenu__item_checked
                    : styles.headerMenu__item;
            }, [props.location]);

            return (
                <div
                    onClick={handleRedirect}
                    key={item.text + item.link}
                    className={getClassName()}
                >
                    {item.text}
                </div>
            );
        })}
    </>
);

export const Items = memo(ItemsComponent);
