import React, { memo, useCallback } from 'react';

import styles from './styles.module.css';
import { history } from '../../../index';
import { routerConstants } from '../../../router-constants';

interface AllProps {
    _id: string;
    title: string;
    backgroundImage: string;
}

const GalleryItemComponent = (props: AllProps) => {
    const { title, _id, backgroundImage } = props;

    const handleRedirectToSingle = useCallback(() => {
        history.push(routerConstants.gallery + '/' + _id);
    }, [_id]);

    return (
        <div
            onClick={handleRedirectToSingle}
            className={styles.galleryItem}
            style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
            <div className={styles.galleryItem__title}>{title}</div>
        </div>
    );
};

export const GalleryItem = memo(GalleryItemComponent);
