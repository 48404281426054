import { action } from 'typesafe-actions';

import { RequestError } from '../types';
import { CategoriesResponseData, CategoriesActionTypes, Query } from './types';

export const getCategories = (
    data: Query,
): { type: CategoriesActionTypes.CATEGORIES_REQUEST; payload: Query } =>
    action(CategoriesActionTypes.CATEGORIES_REQUEST, data);

export const getCategoriesSuccess = (
    data: CategoriesResponseData,
): {
    type: CategoriesActionTypes.CATEGORIES_SUCCESS;
    payload: CategoriesResponseData;
} => action(CategoriesActionTypes.CATEGORIES_SUCCESS, data);

export const getCategoriesError = (
    error: RequestError,
): { type: CategoriesActionTypes.CATEGORIES_ERROR; payload: RequestError } =>
    action(CategoriesActionTypes.CATEGORIES_ERROR, error);
