import { Reducer } from 'redux';

import {
    getDefaultState,
    getIsErrorState,
    getIsFetchedState,
    getIsLoadingState,
} from '../utils/networkGenarators';

import { PhotosActionTypes, photosState } from './types';

export const initialState: photosState = {
    network: getDefaultState(),
    hasMore: true,
    data: {
        categoryName: '',
        photos: [],
    },
};

const reducer: Reducer<photosState> = (state = initialState, action) => {
    switch (action.type) {
        case PhotosActionTypes.PHOTOS_REQUEST: {
            return {
                ...state,
                network: getIsLoadingState(),
            };
        }
        case PhotosActionTypes.PHOTOS_SUCCESS: {
            const newData = [...state.data.photos, ...action.payload.photos];
            const hasMore = Boolean(action.payload.length);
            return {
                ...state,
                data: {
                    photos: newData,
                    categoryName: action.payload.categoryName,
                },
                network: getIsFetchedState(),
                hasMore,
            };
        }
        case PhotosActionTypes.PHOTOS_ERROR: {
            return {
                ...state,
                network: getIsErrorState(action.payload),
            };
        }
        case PhotosActionTypes.PHOTOS_CLEAR: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as photosReducer };
