import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import {
    all,
    AllEffect,
    call,
    CallEffect,
    fork,
    ForkEffect,
    put,
    PutEffect,
    takeEvery,
} from 'redux-saga/effects';

import { getNews as getNewsRequest } from '../../api';
import { RequestError } from '../types';
import { serverErrorMsg } from '../utils/messageConstants';
import { getNews, getNewsSuccess, getNewsError } from './actions';
import { NewsActionTypes, newsState, NewsResponseData } from './types';

function* handleGetNews(
    action: ReturnType<typeof getNews>,
): Generator<
    | PutEffect<{
          type: NewsActionTypes;
          payload?: NewsResponseData | RequestError;
      }>
    | PutEffect<{
          type: NewsActionTypes;
          payload: newsState;
      }>
    | CallEffect<AxiosResponse<NewsResponseData | RequestError>>,
    void,
    AxiosResponse
> {
    try {
        const res = yield call(getNewsRequest);

        if (res.status !== 200) {
            yield put(getNewsError(res.data));
        } else {
            yield put(getNewsSuccess(res.data));
        }
    } catch (err) {
        const serverError = get(err, 'response.data', {});
        const isErrorObject = typeof serverError === 'object';
        if (isErrorObject) {
            yield put(getNewsError(serverError));
        } else {
            yield put(getNewsError({ defaultMessage: serverErrorMsg }));
        }
    }
}

function* watchFetchRequest(): Generator<ForkEffect> {
    yield takeEvery(NewsActionTypes.NEWS_REQUEST, handleGetNews);
}

export function* newsSaga(): Generator<AllEffect<ForkEffect>> {
    yield all([fork(watchFetchRequest)]);
}
