import React, { memo, useEffect } from 'react';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { newsState } from '../../store/news/types';
import { getNews } from '../../store/news/actions';
import { Carousel } from '../../components/carousel-block';
import { Loader } from '../../components/loader';

type PropsFromState = newsState;

interface PropsFromDispatchToProps {
    getNews: typeof getNews;
}

export type AllProps = PropsFromState & PropsFromDispatchToProps;

const NewsContainerComponent = (props: AllProps) => {
    const {
        network: { isLoading, errors },
        getNews,
    } = props;

    useEffect(() => {
        getNews();
    }, []);

    if (isLoading) {
        return <Loader />;
    }

    if (errors) {
        return <>{errors.defaultMessage}</>;
    }

    return <Carousel {...props} />;
};

const mapStateToProps = ({ news }: ApplicationState): PropsFromState => ({
    ...news,
});

const mapDispatchToProps = {
    getNews,
};

const newsContainerMemoize = memo(NewsContainerComponent);

export const NewsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(newsContainerMemoize);
