import { Network, RequestError } from '../types';

export const getDefaultState = (): Network => ({
    isLoading: false,
    isFetched: false,
    errors: undefined,
});

export const getIsLoadingState = (): Network => ({
    isLoading: true,
    isFetched: false,
    errors: undefined,
});

export const getIsFetchedState = (): Network => ({
    isLoading: false,
    isFetched: true,
    errors: undefined,
});

export const getIsErrorState = (errors: RequestError): Network => ({
    isLoading: false,
    isFetched: false,
    errors,
});
