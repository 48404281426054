import React, { memo, ReactElement } from 'react';

import styles from './styles.module.css';

interface AllProps {
    style?: string;
    children: ReactElement;
    onClick: () => void;
}

const ButtonComponent = (props: AllProps) => {
    const className =
        props.style === 'purple' ? styles.button_purple : styles.button;

    return (
        <button onClick={props.onClick} className={className}>
            {props.children}
        </button>
    );
};

export const Button = memo(ButtonComponent);
