import React, { memo } from 'react';
import { RouterState } from 'connected-react-router';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Items } from '../../components/header/header-menu/items';

type PropsFromState = RouterState<{ pathname: string }>;

export type AllProps = PropsFromState & { onClick: () => void };

const HeaderMenuItemsContainerComponent = (props: AllProps) => (
    <Items {...props} />
);

const mapStateToProps = ({ router }: ApplicationState): PropsFromState => ({
    ...router,
});

const HeaderMenuItemsContainerMemoize = memo(HeaderMenuItemsContainerComponent);

export const HeaderMenuItems = connect(mapStateToProps)(
    HeaderMenuItemsContainerMemoize,
);
