import { Network } from '../types';

export enum PhotosActionTypes {
    PHOTOS_REQUEST = '@@photos/PHOTOS_REQUEST',
    PHOTOS_SUCCESS = '@@photos/PHOTOS_SUCCESS',
    PHOTOS_ERROR = '@@photos/PHOTOS_ERROR',
    PHOTOS_CLEAR = '@@photos/PHOTOS_CLEAR',
}

export type photosState = {
    readonly network: Network;
    readonly data: PhotosResponseData;
    readonly hasMore: boolean;
};

export type photos = {
    imageLink: string;
    description: string;
    _id: string;
};

export type PhotosResponseData = {
    categoryName: string;
    photos: photos[];
};

export interface Query {
    skip: number;
    limit: number;
    categoryId: string;
}
