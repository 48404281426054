import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Store } from 'redux';
import { HashRouter } from "react-router-dom";

import { Routes } from './routes';
import { ApplicationState } from './store';

interface MainProps {
    store: Store<ApplicationState>;
    history: History;
}

const Main: React.FC<MainProps> = ({ store, history }):any => {
    return (
        <Provider store={store} context={ReactReduxContext}>
            <ConnectedRouter history={history} context={ReactReduxContext}>
                <Routes />
            </ConnectedRouter>
        </Provider>
    );
};

export default Main;
