import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import styles from './styles.module.css';
import { GallerySingleItem } from './gallery-single-item';
import { ContentWrapper } from '../content-wrapper';
import { GallerySingleCarousel } from './gallery-single-carousel';
import { AllProps } from '../../containers/photos';
import { Loader } from '../loader';
import InfiniteScroll from 'react-infinite-scroller';
import { Breadcrumbs } from './breadcrumbs';

const GallerySingleComponent = (props: AllProps) => {
    const [isCarouselOpened, setCarouselOpened] = useState(false);
    const [initialSlide, setInitialSlide] = useState(0);
    const { categoryId } = useParams();

    const handleLoadMore = useCallback(() => {
        if (props.network.isLoading) return;
        if (props.network.errors) return;
        if (!props.hasMore) return;

        const skip = props.data.photos.length;

        props.getPhotos({ skip, limit: 10, categoryId });
    }, [props.data, props.network, props.hasMore]);

    const handleCloseCarousel = useCallback(() => {
        setCarouselOpened(false);
    }, [isCarouselOpened, setCarouselOpened]);

    const handleOpenCarousel = useCallback(
        (index: number) => {
            setCarouselOpened(true);
            setInitialSlide(index);
        },
        [isCarouselOpened, setCarouselOpened],
    );

    const items = useMemo(() => {
        if (
            props.network.isFetched &&
            !props.data.photos.length &&
            !props.network.errors
        ) {
            return (
                <div className={styles.gallerySingle__isEmpty}>
                    Фотографий в этом альбоме пока нет
                </div>
            );
        }

        return props.data.photos.map((item, i) => {
            const counter = `${i + 1} ${props.data.photos.length}`;
            return (
                <div
                    onClick={handleOpenCarousel.bind(null, i)}
                    className={styles.gallerySingle__item}
                >
                    <GallerySingleItem key={counter} {...item} />
                </div>
            );
        });
    }, [props.data, props.network]);

    useEffect(() => {
        return () => {
            props.getPhotosClear();
        };
    }, []);

    return (
        <div className={styles.gallerySingle}>
            <ContentWrapper>
                <div className={styles.gallerySingle__breadcrumbs}>
                    <Breadcrumbs title={props.data.categoryName} />
                </div>
                <InfiniteScroll
                    loader={<Loader />}
                    hasMore={props.hasMore}
                    loadMore={handleLoadMore}
                    pageStart={0}
                >
                    <div className={styles.gallerySingle__items}>{items}</div>
                </InfiniteScroll>
            </ContentWrapper>
            {isCarouselOpened && (
                <div className={styles.gallerySingle__carousel}>
                    <GallerySingleCarousel
                        categoryName={props.data.categoryName}
                        loadMore={handleLoadMore}
                        initialSlide={initialSlide}
                        onClose={handleCloseCarousel}
                        items={props.data.photos}
                    />
                </div>
            )}
        </div>
    );
};

export const GallerySingle = memo(GallerySingleComponent);
