import React, { ReactElement, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { IndexPage } from './pages/';

import { routerConstants } from './router-constants';
import { MainPage } from './pages/main';
import { ContactsPage } from './pages/contacts';
import { GalleryPage } from './pages/gallery';
import { GallerySinglePage } from './pages/gallery-single';
import { PricePage } from './pages/price';

const RoutesComponent = (): ReactElement => {
    return (
        <Suspense fallback={<div>Загрузка...</div>}>
            <Switch>
                <Route path={routerConstants.contacts} exact>
                    <IndexPage>
                        <ContactsPage />
                    </IndexPage>
                </Route>
                <Route path={routerConstants.root} exact>
                    <IndexPage>
                        <MainPage />
                    </IndexPage>
                </Route>
                <Route path={`${routerConstants.gallery}/:categoryId`} exact>
                    <IndexPage>
                        <GallerySinglePage />
                    </IndexPage>
                </Route>
                <Route path={routerConstants.gallery} exact>
                    <IndexPage>
                        <GalleryPage />
                    </IndexPage>
                </Route>
                <Route path={routerConstants.price} exact>
                    <IndexPage>
                        <PricePage />
                    </IndexPage>
                </Route>
                <Route
                    path={routerConstants.notFound}
                    component={(): ReactElement => <div>Not Found</div>}
                />
            </Switch>
        </Suspense>
    );
};

export const Routes = connect()(RoutesComponent);
