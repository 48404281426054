import { Network } from '../types';

export enum NewsActionTypes {
    NEWS_REQUEST = '@@news/NEWS_REQUEST',
    NEWS_SUCCESS = '@@news/NEWS_SUCCESS',
    NEWS_ERROR = '@@news/NEWS_ERROR',
}

export type newsState = {
    readonly network: Network;
    readonly data: NewsResponseData;
};

export type NewsResponseData = {
    imageLink: string;
    title: string;
    description: string;
    id: string;
}[];
