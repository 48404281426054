import { Reducer } from 'redux';

import {
    getDefaultState,
    getIsErrorState,
    getIsFetchedState,
    getIsLoadingState,
} from '../utils/networkGenarators';

import { AdminInfoActionTypes, adminInfoState } from './types';

export const initialState: adminInfoState = {
    network: getDefaultState(),
    type: 'admin',
    name: '',
};

const reducer: Reducer<adminInfoState> = (state = initialState, action) => {
    switch (action.type) {
        case AdminInfoActionTypes.ADMIN_INFO_REQUEST: {
            return {
                ...state,
                network: getIsLoadingState(),
            };
        }
        case AdminInfoActionTypes.ADMIN_INFO_CHECK_SID_REQUEST: {
            return {
                ...state,
                network: getIsLoadingState(),
            };
        }
        case AdminInfoActionTypes.ADMIN_INFO_SUCCESS: {
            return {
                ...state,
                network: getIsFetchedState(),
            };
        }
        case AdminInfoActionTypes.ADMIN_INFO_ERROR: {
            return {
                ...state,
                network: getIsErrorState(action.payload),
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as adminInfoReducer };
