import { Network } from '../types';

export enum CategoriesActionTypes {
    CATEGORIES_REQUEST = '@@categories/CATEGORIES_REQUEST',
    CATEGORIES_SUCCESS = '@@categories/CATEGORIES_SUCCESS',
    CATEGORIES_ERROR = '@@categories/CATEGORIES_ERROR',
}

export type categoriesState = {
    readonly network: Network;
    readonly data: CategoriesResponseData;
    readonly hasMore: boolean;
};

export type CategoriesResponseData = {
    backgroundImage: string;
    title: string;
    _id: string;
}[];

export interface Query {
    skip: number;
    limit: number;
}
