import React, { memo } from 'react';
import { Header } from '../components/header';
import { Footer } from '../components/footer';

interface AllProps {
    children: JSX.Element | JSX.Element[];
}

export const IndexPageComponent = (props: AllProps) => {
    return (
        <>
            <Header />
            <div>{props.children}</div>
            <Footer />
        </>
    );
};

export const IndexPage = memo(IndexPageComponent);
