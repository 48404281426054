import React, { memo } from 'react';
import { CategoriesContainer } from '../../containers/categories';

const GalleryPageComponent = () => {
    return (
        <>
            <CategoriesContainer />
        </>
    );
};

export const GalleryPage = memo(GalleryPageComponent);
