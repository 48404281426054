import React, { memo } from 'react';

import styles from './styles.module.css';

const GalleryCloseComponent = () => {
    return (
        <div className={styles.galleryClose}>
            <div className={styles.galleryClose__item} />
            <div className={styles.galleryClose__item} />
        </div>
    );
};

export const GalleryClose = memo(GalleryCloseComponent);
