import React, { memo, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ContentWrapper, SIZES } from '../content-wrapper';
import { CarouselItem } from './corousel-item';
import styles from './styles.module.css';
import './carousel-style.css';
import { AllProps } from '../../containers/news';

const CarouselComponent = (props: AllProps) => {
    const { data } = props;

    const getCustomPaging = useCallback(
        () => (
            <div className={styles.carousel__dotWrapper}>
                <div className={styles.carousel__dot} />
            </div>
        ),
        [data],
    );

    const getCustomDotsContainer = useCallback(
        (dots) => (
            <div>
                <div className={styles.carousel__dots}>{dots}</div>
            </div>
        ),
        [data],
    );

    const settings = useMemo(
        () => ({
            customPaging: getCustomPaging,
            appendDots: getCustomDotsContainer,
            dots: true,
            adaptiveHeight: true,
            arrows: false,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }),
        [],
    );

    const items = useMemo(
        () => data.map((item) => <CarouselItem {...item} />),
        [data],
    );

    return (
        <div className={styles.carousel}>
            <ContentWrapper size={SIZES.md}>
                <div className={styles.carousel__content}>
                    <Slider {...settings}>{items}</Slider>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const Carousel = memo(CarouselComponent);
