import React, { memo } from 'react';
import { ActionBlock } from '../../components/action-block';
import { About } from '../../components/about-block';
import { OurProperty } from '../../components/our-property-block';
import { Video } from '../../components/video-block';
import { Map } from '../../components/map-block';
import { NewsContainer } from '../../containers/news';

const MainPageComponent = () => {
    return (
        <>
            <ActionBlock />
            <About />
            <OurProperty />
            <Video />
            <NewsContainer />
            <Map />
        </>
    );
};

export const MainPage = memo(MainPageComponent);
