import Axios, { AxiosResponse } from 'axios';
import { RequestError } from '../store/types';
import { AdminInfoRequest, AdminInfoResponseData } from '../store/admin/types';
import { NewsResponseData } from '../store/news/types';
import { CategoriesResponseData, Query } from '../store/categories/types';
import {
    PhotosResponseData,
    Query as PhotosQuery,
} from '../store/photos/types';

const http = Axios.create({
    baseURL: '/api/v1/',
    timeout: 10000,
    withCredentials: true,
    validateStatus: (status) => {
        return status == 200 || status === 401;
    },
});

export async function getAdminInfo(
    data: AdminInfoRequest,
): Promise<AxiosResponse<AdminInfoResponseData | RequestError>> {
    return await http.post('authenticate-admin', data);
}

export async function checkAdminSid(): Promise<
    AxiosResponse<AdminInfoResponseData | RequestError>
> {
    return await http.get('authenticate-admin');
}

export async function getNews(): Promise<
    AxiosResponse<NewsResponseData | RequestError>
> {
    return await http.get('news');
}

export async function getCategories(
    params: Query,
): Promise<AxiosResponse<CategoriesResponseData | RequestError>> {
    return await http.get('categories', { params });
}

export async function getPhotos(
    params: PhotosQuery,
): Promise<AxiosResponse<PhotosResponseData | RequestError>> {
    return await http.get('photos', { params });
}
