import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper, SIZES } from '../content-wrapper';
import image from './assets/image.svg';
import arrow from './assets/arrow.svg';

const OurPropertyComponent = () => {
    return (
        <div className={styles.ourProperty}>
            <ContentWrapper size={SIZES.xs}>
                <img
                    src={image}
                    className={styles.ourProperty__pcImage}
                    alt=""
                />
                <div className={styles.ourProperty__content}>
                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>01</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Гарантия результата
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>02</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Соблюдение сроков
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>03</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Высокие технологии
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>04</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Качественные материалы
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>05</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Опытные техники
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>06</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Своя курьерская служба
                        </div>
                    </div>

                    <div className={styles.ourProperty__item}>
                        <div className={styles.ourProperty__itemNumber}>07</div>
                        <div className={styles.ourProperty__itemTitle}>
                            Оперативная доставка
                        </div>
                    </div>

                    <img
                        src={arrow}
                        className={styles.ourProperty__arrow}
                        alt=""
                    />
                </div>
            </ContentWrapper>
        </div>
    );
};

export const OurProperty = memo(OurPropertyComponent);
