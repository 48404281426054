import { Reducer } from 'redux';

import {
    getDefaultState,
    getIsErrorState,
    getIsFetchedState,
    getIsLoadingState,
} from '../utils/networkGenarators';

import { NewsActionTypes, newsState } from './types';

export const initialState: newsState = {
    network: getDefaultState(),
    data: [],
};

const reducer: Reducer<newsState> = (state = initialState, action) => {
    switch (action.type) {
        case NewsActionTypes.NEWS_REQUEST: {
            return {
                ...state,
                network: getIsLoadingState(),
            };
        }
        case NewsActionTypes.NEWS_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                network: getIsFetchedState(),
            };
        }
        case NewsActionTypes.NEWS_ERROR: {
            return {
                ...state,
                network: getIsErrorState(action.payload),
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as newsReducer };
