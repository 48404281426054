import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import {
    all,
    AllEffect,
    call,
    CallEffect,
    fork,
    ForkEffect,
    put,
    PutEffect,
    takeEvery,
} from 'redux-saga/effects';

import { getAdminInfo as getAdminInfoRequest, checkAdminSid } from '../../api';
import { RequestError } from '../types';
import { serverErrorMsg } from '../utils/messageConstants';
import {
    checkSid,
    getAdminInfo,
    getAdminInfoError,
    getAdminInfoSuccess,
} from './actions';
import {
    AdminInfoActionTypes,
    adminInfoState,
    AdminInfoResponseData,
} from './types';

function* handleAdminInfo(
    action: ReturnType<typeof getAdminInfo>,
): Generator<
    | PutEffect<{
          type: AdminInfoActionTypes;
          payload?: AdminInfoResponseData | RequestError;
      }>
    | PutEffect<{
          type: AdminInfoActionTypes;
          payload: adminInfoState;
      }>
    | CallEffect<AxiosResponse<AdminInfoResponseData | RequestError>>,
    void,
    AxiosResponse
> {
    try {
        const res = yield call(getAdminInfoRequest, action.payload);

        if (res.status !== 201) {
            yield put(getAdminInfoError(res.data));
        } else {
            yield put(getAdminInfoSuccess(res.data));
        }
    } catch (err) {
        const serverError = get(err, 'response.data', {});
        const isErrorObject = typeof serverError === 'object';
        if (isErrorObject) {
            yield put(getAdminInfoError(serverError));
        } else {
            yield put(getAdminInfoError({ defaultMessage: serverErrorMsg }));
        }
    }
}

function* handleCheckSid(
    action: ReturnType<typeof checkSid>,
): Generator<
    | PutEffect<{
          type: AdminInfoActionTypes;
          payload?: AdminInfoResponseData | RequestError;
      }>
    | PutEffect<{
          type: AdminInfoActionTypes;
          payload: adminInfoState;
      }>
    | CallEffect<AxiosResponse<AdminInfoResponseData | RequestError>>,
    void,
    AxiosResponse
> {
    try {
        const res = yield call(checkAdminSid);

        if (res.status !== 201) {
            yield put(getAdminInfoError(res.data));
        } else {
            yield put(getAdminInfoSuccess(res.data));
        }
    } catch (err) {
        const serverError = get(err, 'response.data', {});
        const isErrorObject = typeof serverError === 'object';
        if (isErrorObject) {
            yield put(getAdminInfoError(serverError));
        } else {
            yield put(getAdminInfoError({ defaultMessage: serverErrorMsg }));
        }
    }
}

function* watchFetchRequest(): Generator<ForkEffect> {
    yield takeEvery(AdminInfoActionTypes.ADMIN_INFO_REQUEST, handleAdminInfo);
}

function* watchFetchSidRequest(): Generator<ForkEffect> {
    yield takeEvery(
        AdminInfoActionTypes.ADMIN_INFO_CHECK_SID_REQUEST,
        handleCheckSid,
    );
}

function* adminInfoSaga(): Generator<AllEffect<ForkEffect>> {
    yield all([fork(watchFetchRequest), fork(watchFetchSidRequest)]);
}

export default adminInfoSaga;
