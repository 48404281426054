import React, { memo } from 'react';

import styles from './styles.module.css';

interface AllProps {
    imageLink: string;
    title: string;
    description: string;
}

const CarouselItemComponent = (props: AllProps) => {
    const { imageLink, title, description } = props;
    return (
        <div className={styles.carouselItem}>
            <div
                className={styles.carouselItem__image}
                style={{ backgroundImage: `url('${imageLink}')` }}
            >
                <img
                    className={styles.carouselItem__imageContent}
                    src={imageLink}
                    alt={title}
                />
            </div>
            <div className={styles.carouselItem__content}>
                <div className={styles.carouselItem__title}>{title}</div>
                <div className={styles.carouselItem__description}>
                    {description}
                </div>
            </div>
        </div>
    );
};

export const CarouselItem = memo(CarouselItemComponent);
