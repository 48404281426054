import { action } from 'typesafe-actions';

import { RequestError } from '../types';
import { PhotosResponseData, PhotosActionTypes, Query } from './types';

export const getPhotos = (
    data: Query,
): { type: PhotosActionTypes.PHOTOS_REQUEST; payload: Query } =>
    action(PhotosActionTypes.PHOTOS_REQUEST, data);

export const getPhotosSuccess = (
    data: PhotosResponseData,
): {
    type: PhotosActionTypes.PHOTOS_SUCCESS;
    payload: PhotosResponseData;
} => action(PhotosActionTypes.PHOTOS_SUCCESS, data);

export const getPhotosError = (
    error: RequestError,
): { type: PhotosActionTypes.PHOTOS_ERROR; payload: RequestError } =>
    action(PhotosActionTypes.PHOTOS_ERROR, error);

export const getPhotosClear = (): { type: PhotosActionTypes.PHOTOS_CLEAR } =>
    action(PhotosActionTypes.PHOTOS_CLEAR);
