import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import {
    all,
    AllEffect,
    call,
    CallEffect,
    fork,
    ForkEffect,
    put,
    PutEffect,
    takeEvery,
} from 'redux-saga/effects';

import { getPhotos as getPhotosRequest } from '../../api';
import { RequestError } from '../types';
import { serverErrorMsg } from '../utils/messageConstants';
import { getPhotos, getPhotosSuccess, getPhotosError } from './actions';
import { PhotosActionTypes, photosState, PhotosResponseData } from './types';

function* handleGetPhotos(
    action: ReturnType<typeof getPhotos>,
): Generator<
    | PutEffect<{
          type: PhotosActionTypes;
          payload?: PhotosResponseData | RequestError;
      }>
    | PutEffect<{
          type: PhotosActionTypes;
          payload: photosState;
      }>
    | CallEffect<AxiosResponse<PhotosResponseData | RequestError>>,
    void,
    AxiosResponse
> {
    try {
        const res = yield call(getPhotosRequest, action.payload);

        if (res.status !== 200) {
            yield put(getPhotosError(res.data));
        } else {
            yield put(getPhotosSuccess(res.data));
        }
    } catch (err) {
        const serverError = get(err, 'response.data', {});
        const isErrorObject = typeof serverError === 'object';
        if (isErrorObject) {
            yield put(getPhotosError(serverError));
        } else {
            yield put(getPhotosError({ defaultMessage: serverErrorMsg }));
        }
    }
}

function* watchFetchRequest(): Generator<ForkEffect> {
    yield takeEvery(PhotosActionTypes.PHOTOS_REQUEST, handleGetPhotos);
}

export function* photosSaga(): Generator<AllEffect<ForkEffect>> {
    yield all([fork(watchFetchRequest)]);
}
