import React, { memo, useCallback, useState } from 'react';

import styles from './styles.module.css';
import { HeaderMenuItems } from '../../../containers/header-menu-items';
import { Burger } from './burger';

const HeaderMenuComponent = () => {
    const [isOpened, setOpened] = useState(false);

    const handleOpened = useCallback(
        (isOpened: boolean) => {
            setOpened(isOpened);
        },
        [isOpened],
    );

    const handleClosed = useCallback(() => {
        setOpened(false);
    }, [isOpened]);

    return (
        <div className={styles.headerMenu}>
            <div className={styles.headerMenu__burger}>
                <Burger isOpened={isOpened} onChange={handleOpened} />
            </div>
            <div
                className={
                    isOpened
                        ? styles.headerMenu__content_opened
                        : styles.headerMenu__content
                }
            >
                <HeaderMenuItems onClick={handleClosed} />
            </div>
            <div
                className={
                    isOpened
                        ? styles.headerMenu__background_opened
                        : styles.headerMenu__background
                }
            />
        </div>
    );
};

export const HeaderMenu = memo(HeaderMenuComponent);
