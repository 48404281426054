import { action } from 'typesafe-actions';

import { RequestError } from '../types';
import { NewsResponseData, NewsActionTypes } from './types';

export const getNews = (): { type: NewsActionTypes } =>
    action(NewsActionTypes.NEWS_REQUEST);

export const getNewsSuccess = (
    data: NewsResponseData,
): { type: NewsActionTypes; payload: NewsResponseData } =>
    action(NewsActionTypes.NEWS_SUCCESS, data);

export const getNewsError = (
    error: RequestError,
): { type: NewsActionTypes; payload: RequestError } =>
    action(NewsActionTypes.NEWS_ERROR, error);
