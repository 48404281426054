import React, { memo, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './styles.module.css';
import { ContentWrapper } from '../../content-wrapper';
import { GallerySingleItem } from '../gallery-single-item';
import { GalleryClose } from '../gallery-close';
import arrowImg from './assets/arrow.svg';

interface AllProps {
    onClose: () => void;
    initialSlide: number;
    loadMore: () => void;
    categoryName: string;
    items: {
        imageLink: string;
        description: string;
        _id: string;
    }[];
}

interface ArrowProps {
    onClick?: () => void;
}

const RightArrow = memo((props: ArrowProps) => (
    <div
        onClick={props.onClick}
        className={styles.gallerySingleCarousel__arrow}
    >
        <img src={arrowImg} alt="" />
    </div>
));

const LeftArrow = memo((props: ArrowProps) => (
    <div
        onClick={props.onClick}
        className={styles.gallerySingleCarousel__arrow_left}
    >
        <img src={arrowImg} alt="" />
    </div>
));

const GallerySingleCarouselComponent = (props: AllProps) => {
    const items = useMemo(
        () =>
            props.items.map((item, i) => {
                const key = `${i + 1} из ${props.items.length}`;
                return (
                    <div
                        key={key}
                        className={styles.gallerySingleCarousel__item}
                    >
                        <GallerySingleItem forSlider {...item} />
                    </div>
                );
            }),
        [props.items],
    );

    const settings = {
        dots: false,
        infinite: true,
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />,
        adaptiveHeight: true,
    };

    const handleChange = useCallback(
        (index) => {
            if (items.length - 5 <= index + 1) {
                props.loadMore();
            }
        },
        [props.items],
    );

    return (
        <div className={styles.gallerySingleCarousel}>
            <div
                className={styles.gallerySingleCarousel__close}
                onClick={props.onClose}
            >
                <GalleryClose />
            </div>
            <ContentWrapper>
                <div className={styles.gallerySingleCarousel__items}>
                    <div className={styles.gallerySingleCarousel__categoryName}>
                        {props.categoryName}
                    </div>
                    <Slider
                        afterChange={handleChange}
                        {...settings}
                        initialSlide={props.initialSlide}
                    >
                        {items}
                    </Slider>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const GallerySingleCarousel = memo(GallerySingleCarouselComponent);
