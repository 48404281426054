import React, { memo } from 'react';

import styles from './styles.module.css';

const LoaderComponent = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.ldsDefault}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export const Loader = memo(LoaderComponent);
