import React, { memo, useCallback, useEffect, useState } from 'react';

import styles from './styles.module.css';
import { ContentWrapper } from '../content-wrapper';
import { Button } from '../button';
import { history } from '../../index';
import { routerConstants } from '../../router-constants';
import ReactMarkdown from 'react-markdown';

import iconFb from '../../assets/icon-fb.svg';
import iconInst from '../../assets/icon-insta.svg';
import iconPhone from '../../assets/icon-phone.svg';

const ActionBlockComponent = () => {
    const handleRedirect = useCallback(() => {
        history.push(routerConstants.contacts);
    }, []);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [imageLink, setImageLink] = useState('');

    useEffect(() => {
        fetch('/api/v1/main', {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
            .then((res) => {
                res = res[0];
                setTitle(res.title ? res.title : '');
                setDescription(res.description ? res.description : '');
                setImageLink(res.imageLink ? res.imageLink : '');
            });
    }, []);

    return (
        <div className={styles.actionBlock}>
            <div
                style={{ backgroundImage: `url(${imageLink})` }}
                className={styles.actionBlock_background}
            />
            <ContentWrapper>
                <div className={styles.actionBlock__card}>
                    <div className={styles.actionBlock__cardTitle}>
                        {/* Коронки из высококачественного <br />
                        оксида циркония&nbsp;- <br className={styles.mobile} />
                        <strong>7&nbsp;000р*</strong>
                        <strong>5&nbsp;000р*</strong> */}
                        <ReactMarkdown children={title} />
                    </div>
                    <div className={styles.actionBlock__cardDescription}>
                        {/* самое&nbsp;выгодное <br className={styles.mobile} />
                        предложение&nbsp;в&nbsp;городе <br />
                        цена&nbsp;при&nbsp;100%&nbsp;предоплате* */}
                        <ReactMarkdown children={description} />
                    </div>
                    <div className={styles.actionBlock__cardButton}>
                        <Button onClick={handleRedirect}>
                            <>перейти</>
                        </Button>
                    </div>
                    <div className={styles.actionBlock__right}>
                        <div className={styles.actionBlock__rightPattern}>
                            <div
                                className={styles.actionBlock__rightPatternItem}
                            />
                            <div
                                className={styles.actionBlock__rightPatternItem}
                            />
                            <div
                                className={styles.actionBlock__rightPatternItem}
                            />
                            <div
                                className={styles.actionBlock__rightPatternItem}
                            />
                        </div>
                        <div className={styles.actionBlock__rightSocials}>
                            <a
                                href="https://mobile.facebook.com/1zublab/"
                                target="_blank"
                                className={styles.actionBlock__rightSocial}
                            >
                                <img src={iconFb} alt="" />
                            </a>
                            <a
                                href="https://instagram.com/1zublab"
                                target="_blank"
                                className={styles.actionBlock__rightSocial}
                            >
                                <img src={iconInst} alt="" />
                            </a>
                            <a
                                href="https://wa.me/89995274028"
                                className={styles.actionBlock__rightSocial}
                            >
                                <img src={iconPhone} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    );
};

export const ActionBlock = memo(ActionBlockComponent);
