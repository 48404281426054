import React, { memo } from 'react';
import { Map } from '../../components/map-block';
import { WorkTimeBlock } from '../../components/work-time-block';
import { ManagerBlock } from '../../components/manager-block';

const ContactsPageComponent = () => {
    return (
        <>
            <WorkTimeBlock />
            <ManagerBlock />
            <Map />
        </>
    );
};

export const ContactsPage = memo(ContactsPageComponent);
