import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import {
    all,
    AllEffect,
    call,
    CallEffect,
    fork,
    ForkEffect,
    put,
    PutEffect,
    takeEvery,
} from 'redux-saga/effects';

import { getCategories as getCategoriesRequest } from '../../api';
import { RequestError } from '../types';
import { serverErrorMsg } from '../utils/messageConstants';
import {
    getCategories,
    getCategoriesSuccess,
    getCategoriesError,
} from './actions';
import {
    CategoriesActionTypes,
    categoriesState,
    CategoriesResponseData,
} from './types';

function* handleGetCategories(
    action: ReturnType<typeof getCategories>,
): Generator<
    | PutEffect<{
          type: CategoriesActionTypes;
          payload?: CategoriesResponseData | RequestError;
      }>
    | PutEffect<{
          type: CategoriesActionTypes;
          payload: categoriesState;
      }>
    | CallEffect<AxiosResponse<CategoriesResponseData | RequestError>>,
    void,
    AxiosResponse
> {
    try {
        const res = yield call(getCategoriesRequest, action.payload);

        if (res.status !== 200) {
            yield put(getCategoriesError(res.data));
        } else {
            yield put(getCategoriesSuccess(res.data));
        }
    } catch (err) {
        const serverError = get(err, 'response.data', {});
        const isErrorObject = typeof serverError === 'object';
        if (isErrorObject) {
            yield put(getCategoriesError(serverError));
        } else {
            yield put(getCategoriesError({ defaultMessage: serverErrorMsg }));
        }
    }
}

function* watchFetchRequest(): Generator<ForkEffect> {
    yield takeEvery(
        CategoriesActionTypes.CATEGORIES_REQUEST,
        handleGetCategories,
    );
}

export function* categoriesSaga(): Generator<AllEffect<ForkEffect>> {
    yield all([fork(watchFetchRequest)]);
}
