import React, { memo } from 'react';

import styles from './styles.module.css';
import { ContentWrapper } from '../content-wrapper';

import backImage from './assets/back-image.svg';

const WorkTimeBlockComponent = () => {
    return (
        <div className={styles.workTimeBlock}>
            <ContentWrapper>
                <div className={styles.workTimeBlock__content}>
                    <div className={styles.workTimeBlock__workTime}>
                        <div className={styles.workTimeBlock__title}>
                            Администрация <br className={styles.mobile} />{' '}
                            лаборатории <br />
                            работает:
                        </div>
                        <div className={styles.workTimeBlock__table}>
                            <div className={styles.workTimeBlock__days}>
                                <div className={styles.workTimeBlock__day}>
                                    Пн - Пт:
                                </div>
                                <div className={styles.workTimeBlock__day}>
                                    Сб:
                                </div>
                                <div className={styles.workTimeBlock__day}>
                                    Вс:
                                </div>
                            </div>
                            <div className={styles.workTimeBlock__times}>
                                <div className={styles.workTimeBlock__time}>
                                    08:00 - 20:00
                                </div>
                                <div className={styles.workTimeBlock__time}>
                                    08:00 - 18:00
                                </div>
                                <div className={styles.workTimeBlock__time}>
                                    выходной
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.workTimeBlock__background}
                        style={{ backgroundImage: `url('${backImage}')` }}
                    />
                </div>
            </ContentWrapper>
        </div>
    );
};

export const WorkTimeBlock = memo(WorkTimeBlockComponent);
