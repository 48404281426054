import React, { memo, ReactNode } from 'react';

import styles from './styles.module.css';

export enum SIZES {
    xs = 'xs', // 1060
    sm = 'sm', // 1300
    md = 'md', // 1600
    lg = 'lg', // 1800 default
}

interface AllProps {
    children: ReactNode;
    size?: SIZES;
}

const ContentWrapperComponent = (props: AllProps) => {
    const { size = SIZES.lg } = props;

    const className =
        size === SIZES.xs
            ? styles.contentWrapper_xsmall
            : size === SIZES.md
            ? styles.contentWrapper_medium
            : size === SIZES.sm
            ? styles.contentWrapper_small
            : styles.contentWrapper;

    return <div className={className}>{props.children}</div>;
};

export const ContentWrapper = memo(ContentWrapperComponent);
